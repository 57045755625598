<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ title }}文章</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
          @click="back"
        >
          <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
          取消
        </button>
        <button
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="save"
        >
          <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
          儲存
        </button>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-3 mt-5">
      <div class="intro-y col-span-12 lg:col-span-9">
        <div class="intro-y box p-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5 flex-col"
          >
            <h2 class="font-medium text-base mr-auto">列表縮圖</h2>
          </div>
          <div class="w-full pt-2">
            <file-uploader
              id="product-thumbnail-uri"
              class="h-72 border rounded"
              mode="image"
              defaultImage="http://placehold.jp/200x188.png?text=375x325"
              :limitedHeight="325"
              :limitedWidth="375"
              :canDelete="canEdit"
              :modelValue="form.formData.Thumbnail?.Uri"
              :action="uploadAction"
              :autoUpload="true"
              @update:modelValue="
                (value) => {
                  if (form.formData) {
                    if (form.formData.Thumbnail)
                      form.formData.Thumbnail.Uri = value;
                    else form.formData.Thumbnail = { Uri: value };
                  }
                }
              "
            />
          </div>
        </div>
        <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5 flex-col"
          >
            <h2 class="font-medium text-base mr-auto">封面照片</h2>
          </div>
          <div class="w-full pt-2">
            <file-uploader
              id="product-photo-uri"
              class="h-72 border rounded"
              mode="image"
              defaultImage="http://placehold.jp/639x300.png?text=996x450"
              :limitedHeight="450"
              :limitedWidth="996"
              :canDelete="canEdit"
              :modelValue="form.formData.Photo?.Uri"
              :action="uploadAction"
              :autoUpload="true"
              @update:modelValue="
                (value) => {
                  if (form.formData) {
                    if (form.formData.Photo) form.formData.Photo.Uri = value;
                    else form.formData.Photo = { Uri: value };
                  }
                }
              "
            />
          </div>
        </div>

        <div class="intro-y box p-5 mt-5">
          <div class="grid grid-flow-row gap-2">
            <div class="grid grid-flow-row gap-1">
              <label>文章標題</label>
              <vxe-input
                placeholder="文章標題"
                class="w-full"
                v-model.trim="validate.Title.$model"
                :class="{ 'bg-red-200': validate.Title.$error }"
              ></vxe-input>
              <template v-if="validate.Title.$error">
                <div
                  v-for="(error, index) in validate.Title.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>

            <div class="grid grid-flow-row gap-1">
              <label>作者</label>
              <vxe-input
                placeholder=""
                class="w-full"
                v-model="form.formData.Author"
              ></vxe-input>
            </div>

            <div class="grid grid-flow-row gap-1">
              <label>引言</label>
              <vxe-textarea
                placeholder=""
                class="w-full"
                v-model="form.formData.Introduction"
              ></vxe-textarea>
            </div>
          </div>
        </div>
        <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">內文</h2>
          </div>
          <div>
            <div class="wysiwyg-box">
              <div class="container-fluid container-xl">
                <div class="row">
                  <div class="col">
                    <CKEditor
                      v-model="form.formData.Content"
                      :editor="classicEditor"
                      :config="simpleEditorConfig"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-y col-span-12 lg:col-span-3">
        <div class="intro-y box p-5">
          <!-- <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">發佈設定</h2>
          </div> -->
          <div class="">
            <div class="flex flex-col p-1 pt-3">
              <label>發佈日期</label>
              <vxe-input
                v-model="form.formData.PublishDate"
                placeholder="發佈日期"
                type="date"
                parse-format="yyyy-dd-MM"
              ></vxe-input>
            </div>
            <div class="flex flex-col p-1">
              <label>上架日期</label>
              <vxe-input
                v-model="form.formData.StartDate"
                placeholder="上架日期"
                type="date"
                parse-format="yyyy-dd-MM"
              ></vxe-input>
            </div>
            <div class="flex flex-col p-1">
              <label>下架日期</label>
              <vxe-input
                v-model="form.formData.EndDate"
                placeholder="下架日期"
                type="date"
                parse-format="yyyy-dd-MM"
              ></vxe-input>
            </div>
          </div>
        </div>
        <div class="intro-y box mt-5 p-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">狀態</h2>
          </div>

          <div class="flex flex-col p-1 pt-3">
            <label>發佈狀態</label>
            <vxe-switch
              v-model="form.formData.Published"
              open-label="上架"
              close-label="下架"
              :disabled="!canEdit"
            ></vxe-switch>
          </div>
          <div class="flex flex-col p-1">
            <label>熱門文章</label>
            <vxe-switch
              v-model="form.formData.IsHot"
              open-label="熱門"
              close-label="一般"
              :disabled="!canEdit"
            ></vxe-switch>
          </div>
          <div class="flex flex-col p-1">
            <label>最新文章</label>
            <vxe-switch
              v-model="form.formData.IsNew"
              open-label="最新"
              close-label="一般"
              :disabled="!canEdit"
            ></vxe-switch>
          </div>
        </div>
        <div class="intro-y box mt-5 p-5">
          <div class="grid grid-flow-row gap-1">
            <label>文章標籤</label>
            <vue-tags-input
              v-model="tag"
              :autocomplete-items="filteredItems"
              :tags="tags"
              @tags-changed="tagChanged"
            />
          </div>
        </div>
      </div>

      <!-- <div class="intro-y col-span-12 lg:col-span-9">
        <div class="intro-y box p-5"></div>
      </div>
      <div class="intro-y col-span-12 lg:col-span-3">
        <div class="intro-y box p-5"></div>
      </div> -->
    </div>

    <div class="w-full sm:w-auto flex pt-4 sm:mt-0">
      <button
        class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
        @click="back"
      >
        <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
        取消
      </button>
      <button
        class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
        @click="save"
      >
        <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
        儲存
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CloudFun, {
  Model,
  defineComponent,
  ref,
  onMounted,
  reactive,
  computed,
  Sorting,
  SortOrder,
  Condition,
  Operator,
  LogicalConnective,
} from "@cloudfun/core";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import formatDate from "xe-utils/toDateString";
import "@/global-components/ckeditor/styles.css";
import "@/assets/css/wysiwyg.css";
import VueTagsInput from "@sipec/vue3-tags-input";
import { CustomFigureAttributes } from "@/global-components/ckeditor/custom";

import {
  required,
  //   minLength,
  //   maxLength,
  //   email,
  //   url,
  //   integer,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { toRefs, watch, nextTick } from "vue";
import { deepEqual } from "fast-equals";
import _ from "lodash";
export default defineComponent({
  components: {
    FileUploader,
    VueTagsInput,
  },
  props: {
    id: {
      type: Number,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const model = CloudFun.current?.model;
    const canEdit = true;
    const tag = ref("");
    const tags = ref([]);
    const hasChanged = ref(false);
    const files = ref([]);
    const tagsItems = ref([]);
    const original = reactive({ formData: {} });
    const currentName = ref("");
    const form = reactive({
      model: "insert",
      formData: {
        Id: 0,
        Published: false,
        PublishDate: new Date(),
        Ordinal: 0,
        ClickCount: 0,
        Remark: "",
        Category: {
          Name: "",
        },
        IsNew: false,
        IsHot: false,
        Introduction: "",
        Author: "",
        Title: "",
        StartDate: "",
        EndDate: "",
        Operator: "",
        OperatorId: "",
        Content: "",
        Photo: {
          Id: 0,
          Uri: "",
        },
        Tags: [],
      },
    });

    const formRules = {
      Title: {
        required,
        // minLength: minLength(2),
      },
      PublishDate: {
        required,
        // email,
      },

      //   },
    };
    const validate = useVuelidate(formRules, toRefs(form.formData));

    function schemaCustomization(editor) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList",
      });

      editor.conversion.attributeToAttribute({
        model: {
          key: "classList",
        },
        view: {
          key: "class",
        },
        converterPriority: "high",
      });
    }

    const classicEditor = ClassicEditor;
    const simpleEditorConfig = {
      extraPlugins: [UploadAdapterPlugin, CustomFigureAttributes],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "sourceEditing",
          "|",
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "alignment",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageStyle:inline",
          "imageStyle:block",
          "imageStyle:side",
          "|",
          "toggleImageCaption",
          "imageTextAlternative",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold custom-big" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold custom-default" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
      fontSize: {
        options: [
          {
            title: "特小",
            model: "custom-tiny-tool",
            view: { name: "span", classes: "custom-tiny" },
          },
          {
            title: "小",
            model: "custom-small-tool",
            view: { name: "span", classes: "custom-small" },
          },
          {
            title: "預設",
            model: "custom-default-tool",
            view: { name: "span", classes: "custom-default" },
          },
          {
            title: "大",
            model: "custom-big-tool",
            view: { name: "span", classes: "custom-big" },
          },
          {
            title: "特大",
            model: "custom-huge-tool",
            view: { name: "span", classes: "custom-huge" },
          },
        ],
      },
    };

    model?.dispatch("tag/query").then(
      (payload) => {
        console.log("tags", payload);
        tagsItems.value = payload.map((item) => {
          return { text: item.Name, id: item.Id };
        });
      },
      (failure) => {}
    );

    onBeforeRouteLeave((to, from) => {
      console.log("hasChanged", _.isEqual(original.formData, form.formData));
      if (hasChanged.value) {
        const answer = window.confirm("尚未儲存，確定離開？");
        return answer;
      }
    });

    watch(form.formData, (first, second) => {
      if (!_.isEqual(original.formData, second)) hasChanged.value = true;
    });

    const filteredItems = computed((i) => {
      return tagsItems.value.filter((i) => {
        return i.text.toLowerCase().indexOf(tag.value.toLowerCase()) !== -1;
      });
    });

    const title = computed(() => {
      return form.model === "insert" ? "新增" : "編輯";
    });

    onMounted(() => {
      console.log("mounted");
      currentName.value = route.params.name.toString();
      form.formData.Category.Name = currentName.value;
      if (route.query && route.query.id) {
        model?.dispatch("news/find", route.query.id).then(
          (payload) => {
            console.log("payload", payload);
            Object.assign(form.formData, payload);
            form.model = "update";
            original.formData = _.cloneDeep(form.formData);
            if (form.formData.Tags && form.formData.Tags.length > 0) {
              tags.value = form.formData.Tags.map((item) => {
                return { text: item.Tag.Name, id: item.Tag.Id };
              });
            }
            nextTick(() => {
              hasChanged.value = false;
            });
          },
          (failure) => {
            console.log("failure", failure);
          }
        );
      }
    });

    const tagChanged = (newTags) => {
      tags.value = newTags;
    };

    // modal 執行 新增or修改
    const save = () => {
      validate.value.$touch();
      console.log(validate.value);
      if (validate.value.$invalid) return;
      var obj = files.value;
      const formData = new FormData();
      if (obj.length > 0) {
        formData.append("file", obj[0]["file"]);
      }
      form.formData.Tags.length = 0;

      if (tags.value.length > 0) {
        tags.value.forEach((item) => {
          form.formData.Tags.push({
            Tag: { Id: item.id, Name: item.text },
            News: {},
            TagId: item.id,
            NewsId: form.formData.Id,
          });
        });
      }

      formData.append("json", JSON.stringify(form.formData));

      var method = form.model === "insert" ? "insert" : "update";
      // show loading
      model
        ?.dispatch(`news/${method}`, formData)
        .then(
          (paylod) => {
            CloudFun.send("info", {
              subject: method === "insert" ? "新增成功" : "更新成功",
              content:
                method === "insert" ? "最新消息新增完成" : "最新消息更新完成",
            });
            hasChanged.value = false;
            router.back();
          },
          (failure) =>
            CloudFun.send("error", { subject: "操作失敗！", content: failure })
        )
        .finally(() => {
          // hide loading
        });
    };

    return {
      classicEditor,
      simpleEditorConfig,
      form,
      canEdit,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      tag,
      tags,
      validate,
      save,
      router,
      route,
      files,
      tagChanged,
      original,
      filteredItems,
      title,
      currentName,
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
});
</script>

<style scoped></style>
